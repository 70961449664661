<template>
  <div class="error-page">
    <div class="error-box text-center">
      <h1>404</h1>
      <h3 class="h2 mb-3">
        <i class="fas fa-exclamation-triangle"></i> Oops! Page not found!
      </h3>
      <p class="h4 font-weight-normal">
        The page you requested was not found.
      </p>
      <router-link :to="{ name: 'Home' }" class="btn btn-primary"
        >Back to Home</router-link
      >
    </div>
  </div>
</template>
<script>
export default {
  name: "404",
};
</script>
<style>
.center-x {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.center-y {
  display: flex;
  align-items: center;
  flex-direction: row;
}
</style>
